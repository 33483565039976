import hardCodedGasData from '../data/hardCodedGasData.json'

export const runtimes = [12, 24, 36, 48]

export function calculate_gas(amount, runtime) {
  // console.log('calculate_gas()', amount, runtime)
  let result = []
  hardCodedGasData.forEach((runtime, runtimeIndex) => {
    const _runtimeData = hardCodedGasData[runtimeIndex]
    _runtimeData['factors'].forEach(factor => {
      if (amount >= factor.min && amount <= factor.max) {
        result[runtimeIndex] = factor.value
      }
    })
  })

  if (typeof runtime === 'undefined') {
    return result
  }
  
  return result[runtimes.indexOf(runtime)] ?? 999
}